/* eslint-disable no-unused-vars */
import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { Notification } from '@mantine/core';
import { CheckCircleIcon } from '@heroicons/react/solid';
import Input from '../../../../_desingModels/layout/components/form/Input/Input';
import PrimaryButton from '../../../../_desingModels/layout/components/Buttons/PrimaryButton';
import Select from '../../../../_desingModels/layout/components/select/Select';
import XIconIconMobile from '../../../../_desingModels/iconComponets/XIcon';
import ErrorMessage from '../../../../_desingModels/layout/components/form/Message/Message';
import {
  useMutateExtraExam,
  useUpdateExtraExam,
} from '../../../../hooks/catalogo';
import H2 from '../../../../_desingModels/textComponents/H2';
import { useSpecialties, useModalities } from '../../../../hooks/users';

function InputContainers({ children }) {
  return (
    <div className="lg:px-10 w-full flex flex-col gap-5 md:flex-row md:gap-2">
      {children}
    </div>
  );
}

function ModalAddExtra({ setOpened, edit }) {
  const fetchSpecialties = useSpecialties();
  const fetchModalities = useModalities();
  const specialties = useMemo(
    () =>
      fetchSpecialties.isSuccess
        ? fetchSpecialties?.data?.data?.especialidades ?? []
        : [],
    [fetchSpecialties]
  );
  const defaultNameEspecialidad = useMemo(
    () => specialties.find((s) => s.value === edit?.id_especialidad),
    [edit, specialties]
  );
  const modalities = useMemo(
    () =>
      fetchModalities.isSuccess
        ? fetchModalities?.data?.data?.modalidades ?? []
        : [],
    [fetchModalities]
  );
  const defaultNameModality = useMemo(
    () => modalities.find((m) => m.value === edit?.id_modalidad),
    [edit, modalities]
  );

  const {
    register,
    control,
    handleSubmit,
    setValue,
    resetField,
    formState: { errors },
  } = useForm();
  const {
    mutate,
    isLoading,
    isSuccess,
    isError,
    reset: resetQuery,
  } = useMutateExtraExam();

  const updateExtra = useUpdateExtraExam();
  const onSubmit = async (dataForm) => {
    try {
      if (edit) {
        const newData = {
          id_servicio: edit.id,
          ...dataForm,
          id_especialidad:
            dataForm.id_especialidad === '0'
              ? edit.id_especialidad
              : dataForm.id_especialidad,
          id_modalidad:
            dataForm.id_modalidad === '0'
              ? edit.id_modalidad
              : dataForm.id_modalidad,
        };
        updateExtra.mutate(newData);
      } else {
        const newData = {
          ...dataForm,
        };
        mutate(newData);
        resetField();
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      <form
        id="servicioExtra"
        onSubmit={handleSubmit(onSubmit)}
        className="pb-5 w-full flex flex-col gap-5 mt-10"
      >
        <InputContainers>
          <div className="w-full md:w-1/2">
            <Input
              modal
              textLabel="Servicio"
              type="text"
              defaultValue={edit ? edit.servicio : ''}
              registerValue={register('servicio', { required: true })}
            />
          </div>
          <div className="w-full md:w-1/2">
            <Input
              modal
              textLabel="Descripcion"
              type="text"
              defaultValue={edit ? edit.descripcion : ''}
              registerValue={register('descripcion', { required: true })}
            />
          </div>
        </InputContainers>
        <InputContainers>
          <div className="w-full md:w-1/2">
            <p className="text-base font-bold truncate text-purpleT mb-1">
              Profesional
            </p>
            <div className="w-full [&_select]:w-full">
              <Select
                itemArray={specialties}
                modal
                setValue={setValue}
                registerValue={register('id_especialidad', { required: true })}
                idSelect="id_especialidad"
                selectBd
                defaultValue={
                  defaultNameEspecialidad
                    ? defaultNameEspecialidad.label
                    : 'Especialidades'
                }
              />
              {specialties.length > 0 ? (
                errors.id_especialidad && (
                  <ErrorMessage xs message={errors.id_especialidad.message} />
                )
              ) : (
                <ErrorMessage xs message="No hay especialidades registradas" />
              )}
            </div>
          </div>
          <div className="w-full md:w-1/2">
            <p className="text-base font-bold truncate text-purpleT mb-1">
              Modalidad
            </p>
            <div className="w-full [&_select]:w-full">
              <Select
                itemArray={modalities}
                modal
                setValue={setValue}
                registerValue={register('id_modalidad', { required: true })}
                idSelect="id_modalidad"
                selectBd
                defaultValue={
                  defaultNameModality
                    ? defaultNameModality.label
                    : 'Modalidades'
                }
              />
              {modalities.length > 0 ? (
                errors.id_modalidad && (
                  <ErrorMessage xs message={modalities.id_modalidad.message} />
                )
              ) : (
                <ErrorMessage xs message="No hay modalidades registradas" />
              )}
            </div>
          </div>
        </InputContainers>
        <InputContainers>
          <H2 text="Precio:" />
        </InputContainers>
        <InputContainers>
          <div className="w-full md:w-1/3">
            <Input
              textLabel="Minimo"
              type="number"
              placeholder="0"
              defaultValue={edit ? edit.minimo : ''}
              registerValue={register('minimo', { required: true })}
              modal
            />
          </div>
          <div className="w-full md:w-1/3">
            <Input
              textLabel="Normal"
              type="number"
              placeholder="0"
              defaultValue={edit ? edit.normal : ''}
              registerValue={register('normal', { required: true })}
              modal
            />
          </div>
          <div className="w-full md:w-1/3">
            <Input
              textLabel="Maximo"
              type="number"
              placeholder="0"
              defaultValue={edit ? edit.alto : ''}
              registerValue={register('alto', { required: true })}
              modal
            />
          </div>
        </InputContainers>
        <div className="mt-4 w-full flex flex-col gap-2 justify-center items-center md:flex-row">
          <PrimaryButton type="submit" text="Guardar" blue />
          <PrimaryButton
            text={isSuccess ? 'Cerrar' : 'Cancelar'}
            modal
            handleClick={() => setOpened(false)}
          />
        </div>
      </form>
      {isLoading ? (
        <Notification loading title="Cargando datos" disallowClose>
          Espere hasta que se carguen los datos, no puede cerrar esta
          notificación aún
        </Notification>
      ) : (
        <>
          {isError ? (
            <Notification
              icon={<XIconIconMobile size={18} />}
              color="red"
              title="Error"
              onClose={resetQuery}
            >
              Ups! Hubo un error al guardar los datos
            </Notification>
          ) : null}

          {isSuccess ? (
            <Notification
              icon={<CheckCircleIcon size={18} />}
              color="teal"
              title="Se actualizo correctamente"
              onClose={resetQuery}
            >
              Se guardarón los datos correctamente
            </Notification>
          ) : null}
        </>
      )}
      {updateExtra.isLoading ? (
        <Notification loading title="Cargando datos" disallowClose>
          Espere hasta que se carguen los datos, no puede cerrar esta
          notificación aún
        </Notification>
      ) : (
        <>
          {updateExtra.isError ? (
            <Notification
              icon={<XIconIconMobile size={18} />}
              color="red"
              title="Error"
              onClose={updateExtra.reset}
            >
              Ups! Hubo un error al guardar los datos
            </Notification>
          ) : null}

          {updateExtra.isSuccess ? (
            <Notification
              icon={<CheckCircleIcon size={18} />}
              color="teal"
              title="Se actualizo correctamente"
              onClose={updateExtra.reset}
            >
              Se guardarón los datos correctamente
            </Notification>
          ) : null}
        </>
      )}
    </>
  );
}

export default ModalAddExtra;
