/* eslint-disable */
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  addUser,
  showUsers,
  showUser,
  showSpecialties,
  updateUser,
  postImage,
  deleteRoleUser,
  addRoleUser,
  updateUserExamPermission,
  deleteUserExam,
  showModalities,
} from '../api/requests';

const key = 'users';

export const useMutateUser = () => {
  const queryClient = useQueryClient();

  return useMutation(addUser, {
    onSuccess: () => {
      queryClient.invalidateQueries(key);
    },
  });
};

export const useUser = () => useQuery(key, showUsers);

export const userById = (idUser) =>
  useQuery(['hola', idUser], () => showUser(idUser), {
    enabled: !!idUser,
  });

export const useMutateUserUpdate = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (idUser) => {
      return updateUser(idUser.id, idUser.userData);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('hola');
      },
    }
  );
};

export const useDeleteRoleUser = () => {
  const queryClient = useQueryClient();

  return useMutation(deleteRoleUser, {
    onSuccess: () => {
      queryClient.invalidateQueries('hola');
    },
  });
};

export const useAddRoleUser = () => {
  const queryClient = useQueryClient();

  return useMutation(addRoleUser, {
    onSuccess: () => {
      queryClient.invalidateQueries('hola');
    },
  });
};

export const useUpdateExamPermission = () => {
  const queryClient = useQueryClient();

  return useMutation(updateUserExamPermission, {
    onSuccess: () => {
      queryClient.invalidateQueries('exams');
    },
  });
};

export const useDeleteExamUser = () => {
  const queryClient = useQueryClient();

  return useMutation(deleteUserExam, {
    onSuccess: () => {
      queryClient.invalidateQueries('exams');
    },
  });
};

export const useSpecialties = () => useQuery('specialties', showSpecialties);

export const useModalities = () => useQuery('modalities', showModalities);

export const useUploadImage = () => {
  const queryClient = useQueryClient();

  return useMutation(postImage, {
    onSuccess: () => {
      queryClient.invalidateQueries('hola');
    },
  });
};
