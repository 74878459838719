/* eslint-disable react/no-array-index-key */
import { Fragment } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Menu, Transition } from '@headlessui/react';
import classNames from '../../../utils/classNames';
import { useStateData } from '../../../../context/dataContext';
// import PropTypes from 'prop-types'

function ComponentMenu({ url, text, navigation }) {
  const navigate = useNavigate();
  const { setAuthenticatedUser } = useStateData();

  const handleLogout = () => {
    window.localStorage.removeItem('user');
    navigate('/');
    setAuthenticatedUser({});
  };

  return (
    <Menu as="div" className="ml-3 relative">
      <div>
        <Menu.Button className="flex items-center justify-center h-8 w-8 rounded-md bg-pinkT text-sm">
          <span className="sr-only">Open user menu</span>
          {text ? (
            <p className="text-white font-semibold text-sm">{text}</p>
          ) : (
            <img className="h-8 w-8 rounded-lg" src={url} alt="" />
          )}
        </Menu.Button>
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
          {navigation.map((item, index) => (
            <Menu.Item key={index}>
              {({ active }) =>
                item.href === '/logout' ? (
                  <Menu.Item>
                    <button
                      type="button"
                      onClick={handleLogout}
                      className={classNames(
                        active ? 'bg-gray-100' : '',
                        'block px-4 py-2 text-sm text-gray-700 w-full text-left'
                      )}
                    >
                      Cerrar Sesión
                    </button>
                  </Menu.Item>
                ) : (
                  <Link
                    to={item.href}
                    className={classNames(
                      active ? 'bg-gray-100' : '',
                      'block px-4 py-2 text-sm text-gray-700'
                    )}
                  >
                    {item.name}
                  </Link>
                )
              }
            </Menu.Item>
          ))}
        </Menu.Items>
      </Transition>
    </Menu>
  );
}

ComponentMenu.propTypes = {};

export default ComponentMenu;
