/* eslint-disable no-unused-vars */
import React from 'react';
import classNames from '../../../utils/classNames';

function CheckBox({ textLabel, value, modal, selectedOption, handleChange }) {
  return (
    <label
      htmlFor={value}
      className={classNames(
        'flex gap-2 justify-center items-center text-negro'
      )}
    >
      {textLabel}
      <input
        className={classNames(
          'border-0 rounded-lg p-1.5  mt-1 text-azul2',
          modal ? 'bg-white border border-indigo-500' : 'bg-blanc1'
        )}
        type="checkbox"
        id={value}
        value={value}
        checked={selectedOption === String(value)}
        onChange={handleChange}
      />
    </label>
  );
}

CheckBox.propTypes = {};

export default CheckBox;
