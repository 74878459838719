/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-no-useless-fragment */
import { useMemo, useState } from 'react';
import HeaderDashboard from '../../../layaouts/headerDashboard/HeaderDashboard';
import Wraper from '../../../layaouts/wrapper/Wrapper';
import Pagination from '../../../layaouts/pagination/Pagination';
import PrimaryButton from '../../../../_desingModels/layout/components/Buttons/PrimaryButton';
import ModalWithBtn from '../../../layaouts/modal/ModalWithBtn';
import ModalForm from './ModalForm';
import Table from './Table';
import { useClient } from '../../../../hooks/clients';
import {
  useShowActividad,
  useShowAntiguedad,
  useShowTamanio,
  useShowDepartameto,
  useShowProvincia,
  useShowDistrito,
  useShowTipoPago,
} from '../../../../hooks/selects';
import { useStateData } from '../../../../context/dataContext';
import Loading from '../../../../_desingModels/layout/components/loading/Loading';
import Notification from '../../../../_desingModels/layout/components/notifications/Notification';

const headItems = [
  { name: 'Código', id: 1 },
  { name: 'Razón Social', id: 2 },
  { name: 'RUC', id: 3 },
  { name: 'Contacto', id: 4 },
  { name: 'Teléfono', id: 5 },
  { name: 'Correo', id: 6 },
  { name: 'Estado', id: 7 },
];

function MainClients({ handleClickDestailClient }) {
  const [opened, setOpened] = useState(false);
  const [searchQuery, setSearchQuery] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 9;
  const { departamento, provincia } = useStateData();

  const { isLoading, isError, data } = useClient();
  const fetchShowActividad = useShowActividad();
  const fetchShowAntiguedad = useShowAntiguedad();
  const fetchShowTamanio = useShowTamanio();
  const fetchShowDepartameto = useShowDepartameto();
  const fetchShowProvincia = useShowProvincia(departamento);
  const fetchShowDistrito = useShowDistrito(departamento, provincia);
  const fetchShowTipoPAgo = useShowTipoPago();

  const newData = useMemo(() => {
    let customClients = data?.data?.clients;

    if (searchQuery && searchQuery?.length > 0) {
      const term = searchQuery.toLowerCase();
      customClients = customClients.filter(
        (item) =>
          item.razonSocial.toLowerCase().includes(term) ||
          item.rucDni.includes(searchQuery)
      );
    }
    return customClients?.sort((a, b) => b.id - a.id);
  }, [data, searchQuery]);

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const newClientes = useMemo(
    () => newData?.slice(startIndex, endIndex),
    [newData, startIndex, endIndex]
  );
  // eslint-disable-next-line no-unsafe-optional-chaining
  const totalPages = Math.ceil(newData?.length / itemsPerPage);

  const handleNext = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevious = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleClick = () => {
    setOpened(true);
  };
  // console.log(fetchShowTipoPAgo, 'pago');
  return (
    <Wraper>
      <HeaderDashboard
        text="Clientes"
        basic
        onSearch={(value) => {
          setSearchQuery(value);
        }}
      >
        <ModalWithBtn
          opened={opened}
          setOpened={setOpened}
          title="Nuevo cliente"
        >
          <ModalForm
            departamento={
              fetchShowDepartameto.isSuccess
                ? fetchShowDepartameto.data.data?.departamentos
                : []
            }
            provincia={
              fetchShowProvincia.isSuccess
                ? fetchShowProvincia.data?.provincias
                : [{ label: '...', value: 0 }]
            }
            distrito={
              fetchShowDistrito.isSuccess
                ? fetchShowDistrito.data?.distritos
                : [{ label: '...', value: 0 }]
            }
            actividad={
              fetchShowActividad.isSuccess
                ? fetchShowActividad.data.data?.actividad
                : []
            }
            antiguedad={
              fetchShowAntiguedad.isSuccess
                ? fetchShowAntiguedad.data.data?.antiguedad
                : []
            }
            tamano={
              fetchShowTamanio.isSuccess
                ? fetchShowTamanio.data.data?.tamanios
                : []
            }
            tipoPago={
              fetchShowTipoPAgo.isSuccess
                ? fetchShowTipoPAgo.data.data?.tiposPago
                : []
            }
            setOpened={setOpened}
          />
        </ModalWithBtn>
        <PrimaryButton text="Nuevo" blue handleClick={handleClick} />
      </HeaderDashboard>
      <div className="w-full px-4 ml-4 flex flex-col md:flex-1">
        {isLoading ? (
          <Loading />
        ) : (
          <>
            {isError ? (
              <Notification message="Oops! hubo un error, intentar más tarde!" />
            ) : (
              <Table
                headItems={headItems}
                bodyItems={newClientes}
                handleClickDestailClient={handleClickDestailClient}
              />
            )}
          </>
        )}
      </div>
      <footer className="w-full p-4 flex justify-center items-center">
        {isLoading ? null : (
          <>
            {isError ? null : data.data.clients?.length > 0 ? (
              <Pagination
                handlePrevious={handlePrevious}
                currentPage={currentPage}
                totalPages={totalPages}
                handleNext={handleNext}
              />
            ) : null}
          </>
        )}
      </footer>
    </Wraper>
  );
}

MainClients.propTypes = {};

export default MainClients;
