/* eslint-disable */
import { useState, useMemo } from 'react';
import {
  Route,
  Routes,
  Outlet,
  useNavigate,
  Navigate,
  useParams,
} from 'react-router-dom';
import Header from '../../layaouts/header/Header';
import MainCommercial from './mainCommercial/MainCommercial';
import MainAdmission from './admission/MainAdmission';
import MainPatients from './patients/MainPatients';
import MainQuotes from './quotes/MainQuotes';
import MainClients from './clients/MainClients';
import MainCitas from './citas/MainCitas';
import DeclaredJurisdictions from './declaredJurisdictions/DeclaredJurisdictions';
import MainPettyCash from './pettyCash/MainPettyCash';
import MainSales from './sales/MainSales';
import MainProtocols from './protocols/MainProtocols';
import NewPatient from '../../layaouts/NewPatient/NewPatient';
import Users from '../management/users/Users';
import UserDeatil from '../management/users/UserDeatil';
import Roles from '../management/roles/Roles';
import DetailClient from './clients/DetailClient';
import { useStateData } from '../../../context/dataContext';
import Page404 from '../Page404/Page404';
import {
  useShowDepartameto,
  useShowDistrito,
  useShowProvincia,
  useShowComboClient,
  useShowComboSede,
  useShowComboContratista,
} from '../../../hooks/selects';
import { userById } from '../../../hooks/users';
import { clientById } from '../../../hooks/clients';
import { patientById } from '../../../hooks/patients';
import {
  quoteById,
  useExamTree,
  useGetConfigQuotes,
  useGetCotiPortada,
  useQuote,
  useQuoteJr,
  useQuoteSr,
} from '../../../hooks/quote';
import DetailQuote from './quotes/DetailQuote';
import DetailProtocol from './protocols/DetailProtocol';
import DetailPatient from './patients/DetailPatient';
import MainQuotesAu from './quotes/MainQuotesAu';
import GenerateExam from './generateExam/GenerateExam';
import CatalogoExamen from './catalogos/CatalogoExamen';
import CatalogoExtra from './catalogos/CatalogoExtra';
import CatalogoPerfiles from './catalogos/CatalogoPerfiles';
import CatalogoTipoEva from './catalogos/CatalogoTipoEva';

const navigationMenu = [
  { name: 'Your Profile', href: '#', current: true },
  { name: 'Settings', href: '#', current: false },
  { name: 'Cerrar Sesión', href: '/logout', current: false },
];
const navigatioSubMenu = [
  { name: 'Cotizaciones', href: 'cotizaciones', current: true },
];
const navigatioSubMenuAuditor = [
  { name: 'Auditoria Cotizaciones', href: 'cotizaciones_au', current: false },
];

const navigatioSubMenuCatalogo = [
  { name: 'Examenes', href: 'catalogoExamen', current: false },
  { name: 'Servicios Extras', href: 'catalogoExtra', current: false },
  { name: 'Perfiles', href: 'catalogoPerfiles', current: false },
  { name: 'Tipo de valuación', href: 'catalogoTipoEva', current: false },
];

function CommercialDasboard({ navigation }) {
  const userData = JSON.parse(window.localStorage.getItem('user'));
  return (
    <>
      <Header
        navigation={navigation}
        navigationMenu={navigationMenu}
        navigatioSubMenu={
          userData?.user?.id_nivel_espertis !== 3
            ? navigatioSubMenu
            : navigatioSubMenuAuditor
        }
        navigationCatalogo={navigatioSubMenuCatalogo}
      />
      <div className="flex flex-col items-center p-4 gap-3 mt-14">
        <Outlet />
      </div>
    </>
  );
}

function CommercialRoutes() {
  const navigate = useNavigate();
  const [quote, setQuote] = useState({});
  const [protocol, setProtocol] = useState({});
  const user = useMemo(() => {
    const loggedUserJSON = window.localStorage.getItem('user');
    return JSON.parse(loggedUserJSON);
  }, []);
  const redirectLink = useMemo(() => {
    return user.modulos && user.modulos?.length > 0
      ? `/dashboard/${user.modulos[0]?.link_href}`
      : `/dashboard/catalogoExamen`;
  }, [user]);
  const { departamento, provincia, departamentoDomi, provinciaDomi, cliente } =
    useStateData();
  const fetchShowDepartameto = useShowDepartameto();
  const fetchShowProvincia = useShowProvincia(departamento);
  const fetchShowDistrito = useShowDistrito(departamento, provincia);
  const fetchShowDepartamentoDomi = useShowDepartameto();
  const fetchShowProvinciaDomi = useShowProvincia(departamentoDomi);
  const fetchShowDistritoDomi = useShowDistrito(
    departamentoDomi,
    provinciaDomi
  );
  const fetchShowComboClient = useShowComboClient();
  const fetchShowComboSede = useShowComboSede(cliente);
  const fetchShowComboContratista = useShowComboContratista(cliente);
  // get user by id
  const userParam = useParams();
  const idUser = userParam['*'].split('/');
  const data = userById(idUser[1]);
  // get client by id
  const clientParams = useParams();
  const idClient = clientParams['*'].split('/');
  const dataClient = clientById(idClient[1]);
  const dataPatient = patientById(idClient[1]);

  const showAllQuotesJr = useQuoteJr();
  const showAllQuotesSr = useQuoteSr();
  const showAllQuotes = useQuote();

  const idQuote = clientParams['*'].split('-');
  const dataQuote = quoteById(idQuote[idQuote.length - 1]);
  const portadaQuote = useGetCotiPortada(idQuote[idQuote.length - 1]);
  const userData = JSON.parse(window.localStorage.getItem('user'));
  // console.log('viendo', idQuote);
  const checkTree = useExamTree();

  const handleClickDestailUser = (event, userData) => {
    event.preventDefault();
    navigate(`usuarios/${userData.id}`);
  };
  const handleClickDestailClient = (event, clientData) => {
    event.preventDefault();
    navigate(`clientes/${clientData.id}`);
  };
  const handleClickDetailQuote = (event, quoteD) => {
    event.preventDefault();
    setQuote(quoteD);
    navigate(`cotizaciones/${quoteD.no_cotizacion}-${quoteD.id}`);
  };
  const handleClickDetailProtocol = (event, protocolD) => {
    event.preventDefault();
    navigate('protocolos/protocol');
  };
  const handleClickDetailPatient = (event, patienD) => {
    event.preventDefault();
    navigate(`pacientes/${patienD.id}`);
  };
  // console.log(
  //  user)
  //   'authenticatedUsers',
  //   user?.modulos.some((e) => e.modulo === 'Pacientes')
  // );
  return (
    <Routes>
      <Route element={<CommercialDasboard navigation={user.modulos} />}>
        <Route
          path="inicio"
          element={
            redirectLink ? <Navigate to={redirectLink} /> : <MainCommercial />
          }
        />
        <Route
          path="admision"
          element={
            user?.modulos.some((e) => e.link_href === 'admision') ? (
              <MainAdmission />
            ) : (
              <Navigate to="../../error-page" />
            )
          }
        />
        <Route
          path="pacientes"
          element={
            user?.modulos.some((e) => e.link_href === 'pacientes') ? (
              <MainPatients
                handleClickDetailPatient={handleClickDetailPatient}
              />
            ) : (
              <Navigate to="../../error-page" />
            )
          }
        />
        <Route
          path="pacientes/nuevo"
          element={
            user?.modulos.some((e) => e.link_href === 'pacientes') ? (
              <NewPatient
                title="Nuevo"
                comboCliente={
                  fetchShowComboClient.isSuccess
                    ? fetchShowComboClient.data.data?.sede_combo
                    : []
                }
                comboSede={
                  fetchShowComboSede.isSuccess
                    ? fetchShowComboSede.data?.sede
                    : []
                }
                comboContratista={
                  fetchShowComboContratista.isSuccess
                    ? fetchShowComboContratista.data?.contratista
                    : []
                }
                departamento={
                  fetchShowDepartameto.isSuccess
                    ? fetchShowDepartameto.data.data?.departamentos
                    : []
                }
                provincia={
                  fetchShowProvincia.isSuccess
                    ? fetchShowProvincia.data?.provincias
                    : [{ label: '...', value: 0 }]
                }
                distrito={
                  fetchShowDistrito.isSuccess
                    ? fetchShowDistrito.data?.distritos
                    : [{ label: '...', value: 0 }]
                }
                departamentoDomi={
                  fetchShowDepartamentoDomi.isSuccess
                    ? fetchShowDepartamentoDomi.data.data?.departamentos
                    : []
                }
                provinciaDomi={
                  fetchShowProvinciaDomi.isSuccess
                    ? fetchShowProvinciaDomi.data?.provincias
                    : [{ label: '...', value: 0 }]
                }
                distritoDomi={
                  fetchShowDistritoDomi.isSuccess
                    ? fetchShowDistritoDomi.data?.distritos
                    : [{ label: '...', value: 0 }]
                }
              />
            ) : (
              <Navigate to="../../error-page" />
            )
          }
        />
        <Route
          path="pacientes/:idPatient"
          element={
            user?.modulos.some((e) => e.link_href === 'pacientes') ? (
              <DetailPatient
                patient={
                  dataPatient.isSuccess
                    ? dataPatient?.data?.paciente[0]?.pacientes[0]
                    : {}
                }
                imagePatient={
                  dataPatient.isSuccess
                    ? dataPatient?.data?.paciente[0]?.files
                    : {}
                }
              />
            ) : (
              <Navigate to="../../error-page" />
            )
          }
        />
        <Route
          path="cotizaciones"
          element={
            user?.modulos.some((e) => e.link_href === 'cotizaciones') ? (
              <MainQuotes
                quoteData={
                  userData?.user?.id_nivel_espertis === 0
                    ? showAllQuotesJr?.data?.data?.cotizaciones
                    : showAllQuotesSr?.data?.data?.cotizaciones
                }
                handleClickDetailQuote={handleClickDetailQuote}
              />
            ) : (
              <Navigate to="../../error-page" />
            )
          }
        />
        <Route
          path="cotizaciones_au"
          element={
            user?.modulos.some((e) => e.link_href === 'cotizaciones') ? (
              userData?.user?.id_nivel_espertis === 3 ? (
                <MainQuotesAu
                  quotedata={showAllQuotes?.data?.data?.cotizaciones}
                  handleClickDetailQuote={handleClickDetailQuote}
                />
              ) : (
                <Navigate to="../../error-page" />
              )
            ) : (
              <Navigate to="../../error-page" />
            )
          }
        />
        <Route
          path="cotizaciones/:idQuote"
          element={
            user?.modulos.some((e) => e.link_href === 'cotizaciones') ? (
              <DetailQuote
                quote={
                  portadaQuote.isSuccess
                    ? portadaQuote?.data?.cotizacion?.[0]
                    : {}
                }
                otrodato={
                  dataQuote.isSuccess
                    ? dataQuote?.data?.info_cotizaciones?.[0]
                    : {}
                }
                examTree={checkTree?.data?.data?.examenes}
              />
            ) : (
              <Navigate to="../../error-page" />
            )
          }
        />
        <Route
          path="clientes"
          element={
            user?.modulos.some((e) => e.link_href === 'clientes') ? (
              <MainClients
                handleClickDestailClient={handleClickDestailClient}
              />
            ) : (
              <Navigate to="../../error-page" />
            )
          }
        />
        <Route
          path="clientes/:idClient"
          element={
            user?.modulos.some((e) => e.link_href === 'clientes') ? (
              <DetailClient
                client={
                  dataClient.isSuccess ? dataClient?.data?.client?.['0'] : {}
                }
              />
            ) : (
              <Navigate to="../../error-page" />
            )
          }
        />
        <Route
          path="citas"
          element={
            user?.modulos.some((e) => e.link_href === 'citas') ? (
              <MainCitas />
            ) : (
              <Navigate to="../../error-page" />
            )
          }
        />
        <Route
          path="declaraciones-juradas"
          element={
            user?.modulos.some(
              (e) => e.link_href === 'declaraciones-juradas'
            ) ? (
              <DeclaredJurisdictions />
            ) : (
              <Navigate to="../../error-page" />
            )
          }
        />
        {/* <Route
          path="caja-chica"
          element={
            user?.modulos.some(
              (e) => e.link_href === 'caja-chica'
            ) ? (
              <MainPettyCash />
            ) : (
              <Navigate to="../../error-page" />
            )
          }
        /> */}
        <Route path="caja-chica" element={<MainPettyCash />} />

        <Route
          path="ventas"
          element={
            user?.modulos.some((e) => e.link_href === 'ventas') ? (
              <MainSales />
            ) : (
              <Navigate to="../../error-page" />
            )
          }
        />
        <Route
          path="protocolos"
          element={
            user?.modulos.some((e) => e.link_href === 'protocolos') ? (
              <MainProtocols
                handleClickDetailProtocol={handleClickDetailProtocol}
              />
            ) : (
              <Navigate to="../../error-page" />
            )
          }
        />
        <Route
          path="protocolos/:idProtocol"
          element={
            user?.modulos.some((e) => e.link_href === 'protocolos') ? (
              <DetailProtocol protocol={protocol} />
            ) : (
              <Navigate to="../../error-page" />
            )
          }
        />
        <Route index element={<MainAdmission />} />
        <Route
          path="usuarios"
          // element={<Users handleClickDestailUser={handleClickDestailUser} />}
          element={
            user?.modulos.some((e) => e.link_href !== 'usuarios') ? (
              <Users handleClickDestailUser={handleClickDestailUser} />
            ) : (
              <Navigate to="../../error-page" />
            )
          }
        />
        <Route
          path="usuarios/:idUser"
          element={
            user?.modulos.some((e) => e.link_href === 'usuarios') ? (
              <UserDeatil
                data={data.isSuccess ? data?.data?.user?.['0'] : {}}
              />
            ) : (
              <Navigate to="../../error-page" />
            )
          }
        />
        <Route
          path="roles"
          // element={<Roles />}
          element={
            user?.modulos.some((e) => e.link_href === 'roles') ? (
              <Roles />
            ) : (
              <Navigate to="../../error-page" />
            )
          }
        />
        <Route path="generateExam" element={<GenerateExam />} />
        <Route path="catalogoExamen" element={<CatalogoExamen />} />
        <Route path="catalogoExtra" element={<CatalogoExtra />} />
        <Route path="catalogoPerfiles" element={<CatalogoPerfiles />} />
        <Route path="catalogoTipoEva" element={<CatalogoTipoEva />} />
        <Route
          path="*"
          element={
            <Page404 to="/" replace message="Oops! La página no existe" />
          }
        />
        <Route
          path="error-page"
          element={
            <Page404 to="/" replace message="Oops! no tienes autorización" />
          }
        />
      </Route>
    </Routes>
  );
}

export default CommercialRoutes;
