/* eslint-disable react/no-array-index-key */
import React from 'react';
// import PropTypes from 'prop-types'

import TableContainer from '../../../../_desingModels/layout/components/table/TableContainer';
import TBody from '../../../../_desingModels/layout/components/table/TBody';
import Thead from '../../../../_desingModels/layout/components/table/Thead';
import Tr from '../../../../_desingModels/layout/components/table/Tr';
import Th from '../../../../_desingModels/layout/components/table/Th';
import Td from '../../../../_desingModels/layout/components/table/Td';
import classNames from '../../../../_desingModels/utils/classNames';
import GenericButton from '../../../../_desingModels/layout/components/Buttons/GenericButton';
import EditIcon from '../../../../_desingModels/iconComponets/EditIcon';
import TrashIcon from '../../../../_desingModels/iconComponets/TrashIcon';
import Notification from '../../../../_desingModels/layout/components/notifications/Notification';

function Table({ headItems = [], bodyItems = [], handleClickDestailClient }) {
  if (bodyItems.length === 0) {
    return (
      <Notification message="No hay registros de Clientes, agregue un nuevo cliente" />
    );
  }
  return (
    <>
      <div className="md:hidden">
        <TableContainer>
          <Thead>
            <Tr>
              {headItems?.map((item, index) => (
                <Th key={index}>{item.name}</Th>
              ))}
            </Tr>
          </Thead>
          <TBody>
            {bodyItems?.map((item, index) => (
              <Tr key={index}>
                <Td full mainText={item.folio ? item.folio : '-'} />
                <Td full mainText={item.razonSocial ? item.razonSocial : '-'} />
                <Td full mainText={item.rucDni ? item.rucDni : '-'} />
                <Td
                  full
                  mainText={item.contacto_name ? item.contacto_name : '-'}
                />
                <Td full mainText={item.telefono ? item.telefono : '-'} />
                <Td full mainText={item.email ? item.email : '-'} />
                <td>
                  <div
                    className={classNames(
                      'rounded-md px-2 py-2 text-center text-blanc1 font-semibold mx-10',
                      item?.estado === 'Al Día'
                        ? 'bg-verde1 opacity-70'
                        : 'bg-negro2 opacity-70',
                      item?.estado === 'Moroso'
                        ? 'bg-naranja1 opacity-70'
                        : 'bg-negro2 opacity-70',
                      item?.estado === 'Cartera P.'
                        ? 'bg-rojo1 opacity-70'
                        : 'bg-negro2 opacity-70'
                    )}
                  >
                    {item.estado ? item.estado : 'Sin estado'}
                  </div>
                </td>
                <td className="text-sm text-gray-900 font-light py-2 whitespace-nowrap flex justify-center gap-5">
                  <GenericButton classNames="bg-azul2 px-2 py-2 rounded-md text-blanc1 hover:bg-azul">
                    <EditIcon />
                  </GenericButton>
                  <GenericButton classNames="bg-rojo1 px-2 py-2 rounded-md text-blanc1 hover:bg-rojo1/90">
                    <TrashIcon />
                  </GenericButton>
                </td>
              </Tr>
            ))}
          </TBody>
        </TableContainer>
      </div>

      <div className="hidden md:flex flex-col">
        <div className="overflow-x-auto overflow-y-auto sm:-mx-6 lg:-mx-8 ">
          <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
            <div className="overflow-hidden">
              <table className="min-w-full">
                <thead className="border-b">
                  <tr>
                    {headItems?.map((item, index) => (
                      <th
                        key={index}
                        scope="col"
                        className="text-sm font-medium text-blue-800 px-6 py-4 text-left"
                      >
                        {item?.name}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {bodyItems?.map((item, index) => (
                    <tr key={index} className="border-b">
                      <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                        {item.folio ? item.folio : '-'}
                      </td>
                      <td className="text-sm text-gray-900 font-light px-6 py-4 w-96">
                        {item.razonSocial ? item.razonSocial : '-'}
                      </td>
                      <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                        {item.rucDni ? item.rucDni : '-'}
                      </td>
                      <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                        {item.contacto_name ? item.contacto_name : '-'}
                      </td>
                      <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                        {item.telefono ? item.telefono : '-'}
                      </td>
                      <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                        {item.email ? item.email : '-'}
                      </td>
                      <td className="text-sm text-gray-900 font-light px-2 py-2 whitespace-nowrap">
                        <div
                          className={classNames(
                            'rounded-md px-2 py-2 text-center text-blanc1 font-semibold',
                            item?.estado === 'Al Día'
                              ? 'bg-verde1 opacity-70'
                              : 'bg-negro2 opacity-70',
                            item?.estado === 'Moroso'
                              ? 'bg-naranja1 opacity-70'
                              : 'bg-negro2 opacity-70',
                            item?.estado === 'Cartera P.'
                              ? 'bg-rojo1 opacity-70'
                              : 'bg-negro2 opacity-70'
                          )}
                        >
                          {item.estado ? item.estado : 'Sin estado'}
                        </div>
                      </td>
                      <td className="text-sm text-gray-900 font-light pl-10 py-2 whitespace-nowrap">
                        <GenericButton
                          classNames="bg-azul2 px-2 py-2 rounded-md text-blanc1 hover:bg-azul"
                          handleClick={(e) => {
                            handleClickDestailClient(e, item);
                          }}
                        >
                          <EditIcon />
                        </GenericButton>
                      </td>
                      <td className="text-sm text-gray-900 font-light py-2 whitespace-nowrap">
                        <GenericButton classNames="bg-rojo1 px-2 py-2 rounded-md text-blanc1 hover:bg-rojo1/90">
                          <TrashIcon />
                        </GenericButton>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

Table.propTypes = {};

export default Table;
