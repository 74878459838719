/* eslint-disable no-sequences */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Notification, Pagination, Tooltip } from '@mantine/core';
import { CheckCircleIcon, TableIcon } from '@heroicons/react/solid';
import { PencilAltIcon, XIcon } from '@heroicons/react/outline';
import { CSVLink } from 'react-csv';
import Wrapper from '../../../layaouts/wrapper/Wrapper';
import HeaderDashHorizont from '../../../layaouts/headerDashboard/HeaderDashHorizont';
import ModalWithBtn from '../../../layaouts/modal/ModalWithBtn';
import ModalAddExtra from './ModalAddExtra';
import { useDeleteExtraExam } from '../../../../hooks/catalogo';
import XIconIconMobile from '../../../../_desingModels/iconComponets/XIcon';

function CatalogoExtra() {
  const [data, setData] = useState([]);
  const [allExtra, setAllExtra] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [newCatExtra, setNewCatExtra] = useState(false);
  const [editCatExtra, setEditCatExtra] = useState(false);
  const [dataEdit, setDataEdit] = useState();
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredData, setFilteredData] = useState(data);
  const deleteExtraExam = useDeleteExtraExam();

  useEffect(() => {
    const timer = setTimeout(() => {
      deleteExtraExam.reset();
    }, 3000);
    return () => clearTimeout(timer);
  }, [deleteExtraExam.isSuccess]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/serviciosExtra/getServiviosExtra`
        );
        const jsonData = await response.json();
        setAllExtra(jsonData);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [deleteExtraExam.isSuccess, editCatExtra]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/serviciosExtra/show?page=${currentPage}`
        );
        const jsonData = await response.json();
        setData(jsonData.data);
        setTotalPages(jsonData.last_page);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [currentPage, newCatExtra, deleteExtraExam.isSuccess, editCatExtra]);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };
  const handleNewExtra = (event) => {
    event.preventDefault();
    setNewCatExtra(true);
  };
  const handleSearch = (event) => {
    const value = event.target.value.toLowerCase();
    setSearchTerm(value);

    const filtered = allExtra.filter(
      (item) =>
        item.servicio.toLowerCase().includes(value) ||
        item.descripcion.toLowerCase().includes(value) ||
        item.id.toString().includes(value) ||
        item.minimo.toString().includes(value) ||
        item.normal.toString().includes(value) ||
        item.alto.toString().includes(value)
    );
    setFilteredData(filtered);
  };
  const handleClickDelete = (idExtra) => {
    const dataDelete = {
      id_servicio: idExtra,
    };
    deleteExtraExam.mutate(dataDelete);
  };
  const handleClickEdit = (dataExtra) => {
    setDataEdit(dataExtra);
    setEditCatExtra(true);
  };
  const headers = [
    { label: 'ID', key: 'id' },
    { label: 'Servicio', key: 'servicio' },
    { label: 'Descripcion', key: 'descripcion' },
    { label: 'P. Minimo', key: 'minimo' },
    { label: 'P. Normal', key: 'normal' },
    { label: 'P. Maximo', key: 'alto' },
  ];
  return (
    <div className="flex w-full flex-col lg:flex-row gap-3">
      <Wrapper nofullScreen>
        <HeaderDashHorizont
          title="Catálogo de Servicios extras"
          handleClick={handleNewExtra}
        >
          <ModalWithBtn
            opened={newCatExtra}
            setOpened={setNewCatExtra}
            title="Agregar Servicio extra"
            tamanio="md"
          >
            <ModalAddExtra setOpened={setNewCatExtra} />
          </ModalWithBtn>
          <ModalWithBtn
            opened={editCatExtra}
            setOpened={setEditCatExtra}
            title="Editar Servicio extra"
            tamanio="md"
          >
            <ModalAddExtra setOpened={setEditCatExtra} edit={dataEdit} />
          </ModalWithBtn>
        </HeaderDashHorizont>
        <section className="fixed right-4 bottom-4 shadow-lg">
          {deleteExtraExam.isLoading ? (
            <Notification loading title="Cargando datos" disallowClose>
              Espere hasta que se carguen los datos, no puede cerrar esta
              notificación aún
            </Notification>
          ) : (
            <>
              {deleteExtraExam.isError ? (
                <Notification
                  icon={<XIconIconMobile size={18} />}
                  color="red"
                  title="Error"
                  onClose={deleteExtraExam.reset}
                >
                  Ups! Hubo un error al guardar los datos
                </Notification>
              ) : null}

              {deleteExtraExam.isSuccess ? (
                <Notification
                  icon={<CheckCircleIcon size={18} />}
                  color="teal"
                  title="Datos guardados"
                  onClose={deleteExtraExam.reset}
                >
                  Se guardarón los datos correctamente
                </Notification>
              ) : null}
            </>
          )}
        </section>
        <div className="w-full overflow-x-auto p-5 flex flex-col items-center gap-5">
          <input
            type="text"
            value={searchTerm}
            onChange={handleSearch}
            placeholder="Buscar..."
            className="p-2 border rounded"
          />
          <table className="max-w-[1000px] table-fixed">
            <thead className="w-full border-b">
              <tr className="w-full">
                <th className="w-[5%] py-2">ID</th>
                <th className="w-[29%] py-2">Servicio</th>
                <th className="w-[20%] py-2">Descripción</th>
                <th className="w-[13%] py-2">Mínimo</th>
                <th className="w-[13%] py-2">Normal</th>
                <th className="w-[13%] py-2">Alto</th>
                <th className="w-[7%] py-2">
                  {allExtra ? (
                    <CSVLink data={allExtra} headers={headers}>
                      <Tooltip label="Descargar todo">
                        <TableIcon className="text-green-600 w-8 h-8" />
                      </Tooltip>
                    </CSVLink>
                  ) : null}
                </th>
              </tr>
            </thead>
            <tbody>
              {searchTerm === ''
                ? data.map((item) => (
                    <tr key={item.id} className="border-b">
                      <td className="text-center py-2">{item.id}</td>
                      <td className="py-2">{item.servicio}</td>
                      <td className="py-2">{item.descripcion}</td>
                      <td className="text-center py-2">{item.minimo}</td>
                      <td className="text-center py-2">{item.normal}</td>
                      <td className="text-center py-2">{item.alto}</td>
                      <td className="text-center py-2 flex gap-1">
                        <Tooltip label="Editar">
                          <button
                            className="bg-purpleT p-1 rounded-md cursor-pointer"
                            type="button"
                            value="X"
                            onClick={() => handleClickEdit(item)}
                          >
                            <PencilAltIcon className="text-blanc1 w-6 h-6" />
                          </button>
                        </Tooltip>
                        <Tooltip label="Eliminar">
                          <button
                            className="bg-rojo1 p-1 rounded-md cursor-pointer"
                            type="button"
                            value="X"
                            onClick={() => handleClickDelete(item.id)}
                          >
                            <XIcon className="text-blanc1 w-6 h-6" />
                          </button>
                        </Tooltip>
                      </td>
                    </tr>
                  ))
                : filteredData.map((item) => (
                    <tr key={item.id} className="border-b">
                      <td className="text-center py-2">{item.id}</td>
                      <td className="py-2">{item.servicio}</td>
                      <td className="py-2">{item.descripcion}</td>
                      <td className="text-center py-2">{item.minimo}</td>
                      <td className="text-center py-2">{item.normal}</td>
                      <td className="text-center py-2">{item.alto}</td>
                      <td className="text-center py-2 flex gap-1">
                        <Tooltip label="Editar">
                          <button
                            className="bg-purpleT p-1 rounded-md cursor-pointer"
                            type="button"
                            value="X"
                            onClick={() => handleClickEdit(item)}
                          >
                            <PencilAltIcon className="text-blanc1 w-6 h-6" />
                          </button>
                        </Tooltip>
                        <Tooltip label="Eliminar">
                          <button
                            className="bg-rojo1 p-1 rounded-md cursor-pointer"
                            type="button"
                            value="X"
                            onClick={() => handleClickDelete(item.id)}
                          >
                            <XIcon className="text-blanc1 w-6 h-6" />
                          </button>
                        </Tooltip>
                      </td>
                    </tr>
                  ))}
            </tbody>
          </table>
          {searchTerm === '' ? (
            <section className="w-full flex justify-center">
              <Pagination
                value={currentPage}
                onChange={handlePageChange}
                total={totalPages}
                sizes={[10, 20, 50]}
                withGoTo
                withEdges
              />
            </section>
          ) : null}
        </div>
      </Wrapper>
    </div>
  );
}

CatalogoExtra.propTypes = {};

export default CatalogoExtra;
