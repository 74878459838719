/* eslint-disable no-unused-vars */
/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react';
import { useStateData } from '../../../../context/dataContext';
import EditIcon from '../../../../_desingModels/iconComponets/EditIcon';
import TrashIconMobile from '../../../../_desingModels/iconComponets/TrashIcon';
import GenericButton from '../../../../_desingModels/layout/components/Buttons/GenericButton';
import PrimaryButton from '../../../../_desingModels/layout/components/Buttons/PrimaryButton';
import H1 from '../../../../_desingModels/textComponents/H1';
import H2 from '../../../../_desingModels/textComponents/H2';
import H3 from '../../../../_desingModels/textComponents/H3';
import P from '../../../../_desingModels/textComponents/P';
import ModalWithBtn from '../../../layaouts/modal/ModalWithBtn';
import Wrapper from '../../../layaouts/wrapper/Wrapper';
import AddContactModal from './AddContactModal';
import AddSubsidiariaModal from './AddSubsidiariaModal';
import {
  useShowDepartameto,
  useShowProvincia,
  useShowDistrito,
  useShowActividad,
  useShowAntiguedad,
  useShowTamanio,
  useShowTipoPago,
} from '../../../../hooks/selects';
import {
  useContactByClient,
  useContratistaByClient,
  useSedeByClient,
  useDeleteContact,
  useDeleteContratista,
  useDeleteSede,
} from '../../../../hooks/clients';
import AddSedeModal from './AddSedeModal';
import ModalForm from './ModalForm';
import EditModalClient from './EditModalClient';

function DetailClient({ client }) {
  const [opened, setOpened] = useState(false);
  const [openedEditContact, setOpenedEditContact] = useState(false);
  const [openedClient, setOpenedClient] = useState(false);
  const [openedSubsidiaria, setOpenedSubsidiaria] = useState(false);
  const [openedEditSubsidiaria, setOpenedEditSubsidiaria] = useState(false);
  const [openedSede, setOpenedSede] = useState(false);
  const [openedEditSede, setOpenedEditSede] = useState(false);
  const [contact, setContact] = useState({});
  const [contratista, setContratista] = useState({});
  const [sede, setSede] = useState({});
  const { departamento, provincia } = useStateData();
  const fetchShowActividad = useShowActividad();
  const fetchShowAntiguedad = useShowAntiguedad();
  const fetchShowTamanio = useShowTamanio();
  const fetchShowDepartameto = useShowDepartameto();
  const fetchShowProvincia = useShowProvincia(departamento);
  const fetchShowDistrito = useShowDistrito(departamento, provincia);
  const fetchShowTipoPAgo = useShowTipoPago();
  // console.log(client, 'cliente');
  const useContact = useContactByClient(client.id);
  const useContratista = useContratistaByClient(client.id);
  const useSede = useSedeByClient(client.id);
  // console.log('sede', useSede?.data?.sedes);
  const mutateDeleteContact = useDeleteContact();
  const mutateDeleteContratista = useDeleteContratista();
  const mutateDeleteSede = useDeleteSede();

  const handleClickEditContact = (data) => {
    setContact(data);
    setOpenedEditContact(true);
  };
  const handleClickDeleteContact = (data) => {
    // console.log('idcontact', data);
    mutateDeleteContact.mutate({ data });
  };

  const handleClickDeleteContratista = (data) => {
    mutateDeleteContratista.mutate({ data });
  };

  const handleClickDeleteSede = (data) => {
    mutateDeleteSede.mutate({ data });
  };

  const handleClick = () => {
    setOpened(true);
  };
  const handleClickEditClient = () => {
    setOpenedClient(true);
  };
  const handleClickSubsidiaria = () => {
    setOpenedSubsidiaria(true);
  };
  const handleClickEditContratista = (data) => {
    setContratista(data);
    setOpenedEditSubsidiaria(true);
  };
  const handleClickSede = () => {
    setOpenedSede(true);
  };
  const handleClickEditSede = (data) => {
    setSede(data);
    setOpenedEditSede(true);
  };
  return (
    <>
      <Wrapper nofullScreen>
        <header className="flex flex-col w-full justify-center items-center p-4 gap-2 md:flex-row md:items-end lg:justify-between">
          <div className="w-full flex flex-col gap-2 lg:flex-row items-center ">
            <H1 text={client?.razonSocial} />
            <H2 text={client.folio ? client?.folio : 'Cod: abc-123'} />
          </div>
          {/* <div className="w-full flex gap-2 lg:flex-row justify-end items-center">
            <GenericButton classNames="bg-azul2 px-2 py-2 rounded-md text-blanc1 hover:bg-azul">
              <EditIcon />
            </GenericButton>
            <GenericButton classNames="bg-azul2 px-2 py-2 rounded-md text-blanc1 hover:bg-azul">
              <EditIcon />
            </GenericButton>
            <GenericButton classNames="bg-azul2 px-2 py-2 rounded-md text-blanc1 hover:bg-azul">
              <EditIcon />
            </GenericButton>
          </div> */}
        </header>
      </Wrapper>
      <div className="flex flex-col lg:flex-row w-full gap-2 h-[430px]">
        <Wrapper nofullScreen>
          <article className="flex justify-between w-full p-4">
            <H2 text="Datos" />
            <div className="flex gap-2">
              <div>{client.tipo_pago}</div>
            </div>
          </article>
          <article className="flex flex-col w-full p-4 ">
            <H3 text="Ruc" blue />
            <P text={client.rucDni} />
            <H3 text="Dirección fiscal" blue />
            <P text={client.ubigeo} />
            {client.actividad && (
              <>
                <H3 text="Actividad" blue />
                <P text={client.actividad} />
              </>
            )}
            <div className="flex flex-wrap gap-2">
              <div>
                <H3 text="Departamento" blue />
                <P text={client.departamento} />
              </div>
              <div>
                <H3 text="Provincia" blue />
                <P text={client.provincia} />
              </div>
              <div>
                <H3 text="Distrito" blue />
                <P text={client.distrito} />
              </div>
            </div>
            {client.antiguedad && (
              <>
                <H3 text="Antiguedad" blue />
                <P text={client.antiguedad} />
              </>
            )}
            {client.tamanio && (
              <>
                <H3 text="Tamaño" blue />
                <P text={client.tamanio} />
              </>
            )}
          </article>
          <article className="flex justify-end w-full p-4">
            <PrimaryButton text="Editar" handleClick={handleClickEditClient} />
            <ModalWithBtn
              opened={openedClient}
              setOpened={setOpenedClient}
              title="Editar cliente"
            >
              <EditModalClient
                dataClient={client}
                departamento={
                  fetchShowDepartameto.isSuccess
                    ? fetchShowDepartameto.data.data?.departamentos
                    : []
                }
                provincia={
                  fetchShowProvincia.isSuccess
                    ? fetchShowProvincia.data?.provincias
                    : [{ label: '...', value: 0 }]
                }
                distrito={
                  fetchShowDistrito.isSuccess
                    ? fetchShowDistrito.data?.distritos
                    : [{ label: '...', value: 0 }]
                }
                actividad={
                  fetchShowActividad.isSuccess
                    ? fetchShowActividad.data.data?.actividad
                    : []
                }
                antiguedad={
                  fetchShowAntiguedad.isSuccess
                    ? fetchShowAntiguedad.data.data?.antiguedad
                    : []
                }
                tamano={
                  fetchShowTamanio.isSuccess
                    ? fetchShowTamanio.data.data?.tamanios
                    : []
                }
                tipoPago={
                  fetchShowTipoPAgo.isSuccess
                    ? fetchShowTipoPAgo.data.data?.tiposPago
                    : []
                }
                setOpened={setOpenedClient}
              />
            </ModalWithBtn>
          </article>
          <article className="flex justify-between w-full p-4">
            <H2 text="Sede" />
          </article>
          <article className="flex justify-between w-full p-4">
            <div>
              <tbody>
                {useSede?.data?.sedes?.map((item, index) => (
                  <tr key={index} className="border-b">
                    <td className="text-sm font-light px-2 py-2 whitespace-nowrap">
                      <div className="flex flex-col">
                        <H3 blue text={`${item?.cede}`} />
                        <P text={item?.numero} />
                      </div>
                    </td>
                    <td className="text-sm text-gray-900 font-light pl-5 py-2 whitespace-nowrap">
                      <GenericButton
                        classNames="bg-azul2 px-2 py-2 rounded-md text-blanc1 hover:bg-azul"
                        handleClick={() => handleClickEditSede(item)}
                      >
                        <EditIcon />
                      </GenericButton>
                      <ModalWithBtn
                        opened={openedEditSede}
                        setOpened={setOpenedEditSede}
                        title="Editar Sede"
                      >
                        <AddSedeModal
                          departamento={
                            fetchShowDepartameto.isSuccess
                              ? fetchShowDepartameto.data.data?.departamentos
                              : []
                          }
                          provincia={
                            fetchShowProvincia.isSuccess
                              ? fetchShowProvincia.data?.provincias
                              : [{ label: '...', value: 0 }]
                          }
                          distrito={
                            fetchShowDistrito.isSuccess
                              ? fetchShowDistrito.data?.distritos
                              : [{ label: '...', value: 0 }]
                          }
                          setOpened={setOpenedEditSede}
                          dataSede={sede}
                          editModal
                        />
                      </ModalWithBtn>
                    </td>
                    <td className="text-sm text-gray-900 font-light py-2 pl-2 whitespace-nowrap">
                      <GenericButton
                        classNames="bg-rojo1 px-2 py-2 rounded-md text-blanc1 hover:bg-rojo1/90"
                        handleClick={() => {
                          handleClickDeleteSede(item.id);
                        }}
                      >
                        <TrashIconMobile />
                      </GenericButton>
                    </td>
                  </tr>
                ))}
              </tbody>
            </div>
          </article>
          <article className="flex justify-end w-full p-4">
            <PrimaryButton text="Agregar" handleClick={handleClickSede} />
          </article>
          <ModalWithBtn
            opened={openedSede}
            setOpened={setOpenedSede}
            title="Agregar Sede"
          >
            <AddSedeModal
              departamento={
                fetchShowDepartameto.isSuccess
                  ? fetchShowDepartameto.data.data?.departamentos
                  : []
              }
              provincia={
                fetchShowProvincia.isSuccess
                  ? fetchShowProvincia.data?.provincias
                  : [{ label: '...', value: 0 }]
              }
              distrito={
                fetchShowDistrito.isSuccess
                  ? fetchShowDistrito.data?.distritos
                  : [{ label: '...', value: 0 }]
              }
              setOpened={setOpenedSede}
              idClient={client.id}
            />
          </ModalWithBtn>
        </Wrapper>
        <Wrapper nofullScreen>
          <article className="flex justify-between w-full p-4">
            <H2 text="Contacto" />
          </article>
          <article className="flex justify-between w-full p-4">
            <div>
              <tbody>
                {useContact?.data?.contactos?.map((item, index) => (
                  <tr key={index} className="border-b">
                    <td className="text-sm font-light px-2 py-2 whitespace-nowrap">
                      <div className="flex flex-col">
                        <H3
                          blue
                          text={`${item?.nombre} ${item?.apellido_paterno}`}
                        />
                        <P text={item?.area_pertenece} />
                      </div>
                    </td>
                    <td className="text-sm font-light px-2 py-2 whitespace-nowrap">
                      <div className="flex flex-col">
                        <P text={item?.telefono} />
                        <P text={`${item?.email}`} />
                      </div>
                    </td>
                    <td className="text-sm text-gray-900 font-light pl-5 py-2 whitespace-nowrap">
                      <GenericButton
                        classNames="bg-azul2 px-2 py-2 rounded-md text-blanc1 hover:bg-azul"
                        handleClick={() => handleClickEditContact(item)}
                      >
                        <EditIcon />
                      </GenericButton>
                      <ModalWithBtn
                        opened={openedEditContact}
                        setOpened={setOpenedEditContact}
                        title="Editar Contacto"
                      >
                        <AddContactModal
                          dataContact={contact}
                          setOpened={setOpenedEditContact}
                          editModal
                        />
                      </ModalWithBtn>
                    </td>
                    <td className="text-sm text-gray-900 font-light py-2 pl-2 whitespace-nowrap">
                      <GenericButton
                        classNames="bg-rojo1 px-2 py-2 rounded-md text-blanc1 hover:bg-rojo1/90"
                        handleClick={() => {
                          handleClickDeleteContact(item.id);
                        }}
                      >
                        <TrashIconMobile />
                      </GenericButton>
                    </td>
                  </tr>
                ))}
              </tbody>
            </div>
          </article>
          <article className="flex justify-end w-full p-4">
            <PrimaryButton text="Agregar" handleClick={handleClick} />
          </article>
          <ModalWithBtn
            opened={opened}
            setOpened={setOpened}
            title="Agregar Contacto"
          >
            <AddContactModal idClient={client.id} setOpened={setOpened} />
          </ModalWithBtn>
        </Wrapper>
        <Wrapper nofullScreen>
          <article className="flex justify-between w-full p-4">
            <H2 text="Subsidiarias" />
          </article>
          <article className="flex justify-between w-full p-4">
            <div>
              <tbody>
                {useContratista?.data?.contratistas?.map((item, index) => (
                  <tr key={index} className="border-b">
                    <td className="text-sm font-light px-2 py-2 whitespace-nowrap">
                      <div className="flex flex-col">
                        <H3 blue text={`${item?.razonSocial}`} />
                        <P text={item?.rucDni} />
                      </div>
                    </td>
                    <td className="text-sm text-gray-900 font-light pl-5 py-2 whitespace-nowrap">
                      <GenericButton
                        classNames="bg-azul2 px-2 py-2 rounded-md text-blanc1 hover:bg-azul"
                        handleClick={() => handleClickEditContratista(item)}
                      >
                        <EditIcon />
                      </GenericButton>
                      <ModalWithBtn
                        opened={openedEditSubsidiaria}
                        setOpened={setOpenedEditSubsidiaria}
                        title="Editar Subsidiaria"
                      >
                        <AddSubsidiariaModal
                          departamento={
                            fetchShowDepartameto.isSuccess
                              ? fetchShowDepartameto.data.data?.departamentos
                              : []
                          }
                          provincia={
                            fetchShowProvincia.isSuccess
                              ? fetchShowProvincia.data?.provincias
                              : [{ label: '...', value: 0 }]
                          }
                          distrito={
                            fetchShowDistrito.isSuccess
                              ? fetchShowDistrito.data?.distritos
                              : [{ label: '...', value: 0 }]
                          }
                          setOpened={setOpenedEditSubsidiaria}
                          idClient={client.id}
                          dataSubsidiaria={contratista}
                          editModal
                        />
                      </ModalWithBtn>
                    </td>
                    <td className="text-sm text-gray-900 font-light py-2 pl-2 whitespace-nowrap">
                      <GenericButton
                        classNames="bg-rojo1 px-2 py-2 rounded-md text-blanc1 hover:bg-rojo1/90"
                        handleClick={() => {
                          handleClickDeleteContratista(item.id);
                        }}
                      >
                        <TrashIconMobile />
                      </GenericButton>
                    </td>
                  </tr>
                ))}
              </tbody>
            </div>
          </article>
          <article className="flex justify-end w-full p-4">
            <PrimaryButton
              text="Agregar"
              handleClick={handleClickSubsidiaria}
            />
          </article>
          <ModalWithBtn
            opened={openedSubsidiaria}
            setOpened={setOpenedSubsidiaria}
            title="Agregar Subsidiaria"
          >
            <AddSubsidiariaModal
              departamento={
                fetchShowDepartameto.isSuccess
                  ? fetchShowDepartameto.data.data?.departamentos
                  : []
              }
              provincia={
                fetchShowProvincia.isSuccess
                  ? fetchShowProvincia.data?.provincias
                  : [{ label: '...', value: 0 }]
              }
              distrito={
                fetchShowDistrito.isSuccess
                  ? fetchShowDistrito.data?.distritos
                  : [{ label: '...', value: 0 }]
              }
              setOpened={setOpenedSubsidiaria}
              idClient={client.id}
            />
          </ModalWithBtn>
        </Wrapper>
      </div>
    </>
  );
}

DetailClient.propTypes = {};

export default DetailClient;
